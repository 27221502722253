/*
=============== 
Variables
===============
*/

:root {
  --lafama-main-color: #fbb123;
  background-color: #000;
}

html {
  scroll-padding-top: 55px; /* height of your navbar */
  background-color: #000;
}

.main {
  background-color: #000;
  color: #fff;
}

/* colors */
.primary-color {
  color: var(--lafama-main-color);
}

/* custom styles */

.swiper-button-disabled {
  display: none !important;
}

@media screen and (min-width: 721px) {
  .category-item-element:hover {
    background-color: #ffbf48;
    border-color: #ffbf48;
    color: #000;
  }
}

.active-category:hover {
  background-color: #d7961f !important;
  border-color: #d7961f !important;
  color: #000 !important;
}

.active-category {
  background-color: var(--lafama-main-color);
  border-color: var(--lafama-main-color);
  color: #000;
}

.category-item-element {
  border: 1px;
  border-radius: 50rem;
  display: flex;
  height: 2.2rem;
}

.swiper-button-prev {
  background-color: #acacac;
  border-color: #acacac;
  color: #000000;
  border: 1px;
  border-radius: 50rem;
}

.swiper-button-prev:after {
  color: #000000;
}

.categories-menu-items {
  width: calc(100%);
}

@media screen and (min-width: 721px) {
  .categories-menu-items {
    width: calc(100% - 46px - 34px);
  }
}

@media screen and (min-width: 721px) {
  .categories-menu {
    overflow: hidden;
    width: 100%;
  }
}

.categories-menu {
  -webkit-box-align: center;
  align-items: center;
  display: -webkit-box;
  display: flex;
  position: relative;
  background-color: black;
  padding: 10px;
  /* width: calc(100% - 24px); */
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.next-button {
  margin-left: auto;
}

.swiper-slide {
  width: auto !important;
  flex-shrink: 0 !important;
  height: 100% !important;
  position: relative !important;
}

.menu-category-title {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  font-size: 1rem;
  font-weight: 500;
  line-height: 2.2rem;
}

.logo {
  background-color: #000;
}

.card {
  background-color: #f8f8f8;
}

.btn-nav-category {
  background-color: #fbb123;
}
.phone {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5rem;
}
.instagram {
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.5rem;
}
.location {
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.5rem;
}
.creator {
  color: white;
  font-size: 1rem;
  font-weight: 300;
  line-height: 0.4rem;
}
.weight {
  color: white;
  font-size: 1.2rem;
  font-weight: 400;
  margin-left: 0.5rem;
}

.menu-description {
  color: white;
  font-size: 1.2rem;
  font-weight: 400;
  margin-left: 0.5rem;
  font-style: italic;
}

.price {
  min-width: 6rem;
}

.spacer {
  height: 2rem;
  width: 100%;
}

.card-border-bottom {
  background-color: #000 !important;
  border-right: none;
  border-left: none;
  border-top: none;
  border-radius: 0px !important;
  border-color: #202020 !important;
}

.item-text {
  color: white;
}

.image-zoom [data-rmiz-modal-overlay="hidden"] {
  background-color: black;
}
.image-zoom [data-rmiz-modal-overlay="visible"] {
  background-color: black;
}
.image-zoom [data-rmiz-btn-unzoom] {
  background-color: black;
}

.glf-button {
  color: #fbb123 !important;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}

.lafama-color {
  color: #fbb123 !important;
}

.gloriafood-button,
.glf-button {
  background-color: #fbb123 !important;
}

